<template>
  <!-- 场景 -->
  <div class="box-card" style="text-align: left; background-color: #f2f2f2;">
    <ul class="scene_box">
      <li class="scene_item" v-for="(item, index) in tableData" :key="index" @click="openWindow(item)"
        @mouseleave="chooseScen = ''">
        <div class="scene_item_inner" @mouseover="handleIconBox(index)">
          <!-- <img fit="contain"
            :src="require(`@/assets/images/scene/scene${item.scene_icon}.png`)"> -->
          <!-- <img fit="contain"
            :src="show3DIconBox && chooseScen === index ? require(`@/assets/images/scene/scene13D.gif`) : require(`@/assets/images/scene/scene${item.scene_icon}.png`)"> -->
          <div class="backgroundBox" :style="{
            'backgroundImage':item.scene_icon.indexOf('-')!==-1? show3DIconBox && chooseScen === index ?
              item.scene_icon.split('-')[0] > 4 ? 'url(' + require(`@/assets/images/scene/scene${item.scene_icon}.png`) + ')' :
                'url(' + require(`@/assets/images/scene/scene${item.scene_icon.split('-')[0]}-3D.gif`) + ')'
              : item.scene_icon.split('-')[0] > 4 ? 'url(' + require(`@/assets/images/scene/scene${item.scene_icon}.png`) + ')' : 'url(' + require(`@/assets/images/scene/sceneBack${item.scene_icon.split('-')[0]}.png`) + ')':'url(' + require(`@/assets/images/scene/scene5-1.png`) + ')',
          }">
            <!-- 内部小图标 -->
            <div class="iconInnerBox" v-if="item.scene_icon.split('-')[0]<= 4 &&item.scene_icon.indexOf('-')!==-1" :style="{
              'backgroundImage': 'url(' + require(`@/assets/images/scene/sceneIcon${item.scene_icon}.png`) + ')'
            }">
            </div>
          </div>
          <span>{{ item.scene_name }}</span>
          <div class="icon_box" v-show="chooseScen === index">
            <i class="iconfont lebo-bianji" @click.stop.prevent="goEditPage(item._id)"></i>
            <i class="iconfont lebo-shanchu1" @click.stop.prevent="deletes(item._id)"></i>
          </div>
        </div>
      </li>
      <li class="scene_item add" @click.stop.prevent="goEditPage('')">
        <i class="el-icon-circle-plus-outline"></i>
        <span>新增场景</span>
      </li>
    </ul>
    <!-- 新增编辑场景 -->
    <lebo-dialog :title="dialogSceneAddConfigurationId ? '编辑场景' : '新增场景'" :isShow="showSceneAddConfiguration" width="70%"
      @close="showSceneAddConfiguration = false" footerSlot>
      <sceneAddConfiguration v-if="showSceneAddConfiguration" :id="dialogSceneAddConfigurationId"
        @closeDialog="closeSceneAddConfiguration"></sceneAddConfiguration>
    </lebo-dialog>
    <!-- <template v-for="item in tableData">
    <span style="width: 10%">
      <template>
        <span class="title" :size="50" style="text-align: top">{{ item.scene_name }}</span>
        <el-avatar shape="square" :size="200" :src="require(`../../assets/images/group/theme${item.scene_icon}.png`)"></el-avatar>
        <lbButton type="warning" style="text-align: left" icon="bianji" hint="编辑" @click="goEditPage(item._id)"></lbButton>
        <lbButton type="warning" icon="shanchu" hint="删除" style="text-align: left" @click="deletes(item._id)"></lbButton>
        <lbButton type="warning" icon="xinzeng" hint="新增" style="text-align: left" >
        </lbButton>
      </template>
    </span>
  </template> -->
  </div>
</template>

<script>
import systemapi from '@/api/systemapi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import sceneAddConfiguration from './components/sceneAddConfiguration/index.vue'
export default {
  components: { sceneAddConfiguration },
  data () {
    return {
      // 表格数据
      tableData: [],
      deleteArray: [],
      dialogSceneAddConfigurationId: '',
      showSceneAddConfiguration: false,
      chooseScen: '',
      show3DIconBox: false
    }
  },
  created () {
    this.requestList()
    this.setShowModule(false)
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus'])
  },
  mounted () {
    console.log('场景')
    this.setIsWorkSpace(true)
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  destroyed () {
    this.setIsWorkSpace(false)
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus', 'setShowModule']),
    ...mapMutations('menuList', ['setIsWorkSpace']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 删除
    deletes (_id) {
      this.deleteArray.push(_id)
      if (this.deleteArray.length === 0) {
        this.$msg.warning('请选择要删除的条目！')
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteItemRequest({ _ids: [_id] })
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 编辑
    goEditPage (_id) {
      this.dialogSceneAddConfigurationId = _id
      this.showSceneAddConfiguration = true
      // this.$router.push({
      //   path: '/scene-add-configuration',
      //   query: {
      //     id: _id
      //   }
      // })
    },
    async requestList () {
      try {
        const res = await systemapi.getSceneList()
        this.tableData = res.Data
      } catch (error) { }
    },
    async deleteItemRequest (data) {
      try {
        console.log('_id=' + JSON.stringify(data))
        const res = await systemapi.deleteSceneList(data)
        console.log('==--=res-=', JSON.stringify(res))
        this.$msg.success('删除成功!')
        this.requestList()
      } catch (error) { }
    },
    // 打开新页面
    openWindow (item) {
      var url = `http://${item.domain_address}/webmanage/home?access_toke=${sessionStorage.getItem('token')}&refresh_token=${sessionStorage.getItem('refreshtoken')}`
      console.log('跳转', url)

      window.open(url, item.scene_name)
    },
    closeSceneAddConfiguration () {
      this.showSceneAddConfiguration = false
      this.requestList()
    },
    // 鼠标移入显示编辑和删除图标
    handleIconBox (index) {
      this.chooseScen = index
      this.show3DIconBox = true
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  width: 100%;
  height: 100%;
}

.scene_box {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  .scene_item {
    // width: 330px;
    height: 400px;
    width: 18.7%;
    // height: 46%;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    margin-left: 1%;
    margin-top: 1%;
    font-size: 22px;
    color: #666666;
    cursor: pointer;

    .scene_item_inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .backgroundBox {
        width:200px;
        height: 200px;
        margin-bottom: 20px;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;

        .iconInnerBox {
          width: 50px;
          height: 50px;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      img {
        width: 50%;
        height: 50%;
        margin-bottom: 20px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1),
        inset 0px -4px 4px 0px rgba(0, 0, 0, 0.1);

      // .icon_box {
      //   display: block;
      // }
    }

    .el-image {
      width: 50%;
      height: 50%;
      margin-bottom: 20px;
    }

    .icon_box {
      display: block;
      margin-top: 10px;

      .iconfont {
        font-size: 18px;
      }

      .lebo-bianji {
        color: #e39f28;
        margin-right: 30px;
      }

      .lebo-shanchu1 {
        color: #f55265;
      }
    }
  }

  .add {
    font-size: 22px;
    color: #cccccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .el-icon-circle-plus-outline {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}
</style>
